<template>
    <div class="separator">
      <p class="text" v-for="i in 50" :key="i">
        $TOP
      </p>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="sass">
.separator
  width: 100vw
  border-top: 5px solid #e83333
  border-bottom: 5px solid #e83333
  outline: 2px solid black
  height: 60px
  background-color: white
  display: flex
  align-items: center
  overflow: hidden
  white-space: nowrap
.text
  display: inline-block
  animation: news-ticker 10s linear alternate infinite
  font-size: 22pt
  margin-right: 60px
@keyframes news-ticker
  0%
    transform: translateX(10%)
  100%
    transform: translateX(-1000%)
</style>